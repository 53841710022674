import "core-js/modules/es6.array.map.js";
import "core-js/modules/es6.array.filter.js";
import _ from 'lodash';
import { useUpdateSelectedOptionsMutation } from '~/types/types';
export default defineComponent({
  inheritAttrs: false,
  props: {
    userInput: {
      type: Object,
      default: function _default() {}
    },
    isEditable: {
      type: Boolean,
      default: false
    },
    isMultiple: {
      type: Boolean,
      default: false
    },
    options: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  setup: function setup() {
    var _useUpdateSelectedOpt = useUpdateSelectedOptionsMutation(),
      updateSelectedOptions = _useUpdateSelectedOpt.mutate,
      onDone = _useUpdateSelectedOpt.onDone;
    onDone(function () {
      window.$nuxt.$emit('userMsg');
    });
    return {
      updateSelectedOptions: updateSelectedOptions
    };
  },
  computed: {
    translatedOptions: function translatedOptions() {
      var _this = this;
      return this.options.map(function (option) {
        return {
          id: option.id,
          label: _this.isEmpty(option.ixId) ? option.label : _this.$t("database.option.".concat(option.ixId, ".label"))
        };
      });
    },
    value: function value() {
      var values = this.$props.userInput.selectedOptions.map(function (e) {
        return e.id;
      });
      return this.isMultiple ? values : values[0];
    }
  },
  methods: {
    isEmpty: function isEmpty(item) {
      return _.isEmpty(item);
    },
    getLabelText: function getLabelText(item) {
      if (this.isEmpty(item.ixId)) return item.label;else return 'database.option.' + item.ixId + '.label';
    },
    valueChange: function valueChange(value) {
      var values;
      if (_.isArray(value)) values = value.filter(function (e) {
        return e !== null && e !== undefined;
      });else if (value !== null && value !== undefined) values = [value];else values = [];
      this.updateSelectedOptions({
        userInputId: this.$props.userInput.id,
        values: values
      });
    }
  }
});
import { render, staticRenderFns } from "./SmallOptions.vue?vue&type=template&id=10f1deb4&scoped=true"
import script from "./SmallOptions.vue?vue&type=script&lang=ts"
export * from "./SmallOptions.vue?vue&type=script&lang=ts"
import style0 from "./SmallOptions.vue?vue&type=style&index=0&id=10f1deb4&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "10f1deb4",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IxSurveyQuestion: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/survey/Question.vue').default,IxFormInputSmallOptions: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/form-input/SmallOptions.vue').default})


/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VInput } from 'vuetify/lib/components/VInput';
import { VLayout } from 'vuetify/lib/components/VGrid';
installComponents(component, {VInput,VLayout})
